import { useCallback, useEffect, useState, useTransition } from 'react';
import { useQuery } from '@apollo/client';
import { useLanguage } from 'components/util/Language';
import { AUTOCOMPLETE_SEARCH_QUERY, CONTENT_SEARCH_QUERY } from 'views/main/queries.Main';
import {
  AutocompleteSearchQuery,
  ContentSearchQuery,
  ContentSearchQueryResult,
} from 'generated';
import { debounce } from 'components/fields/selectors/templates/SearchDropdown/debounce';
import { useLocalStorage } from 'components/util/LocalStorage';

interface SearchHistoryItem {
  searchDate: string;
  rank: number;
  keys: string[];
  result: {
    label: string;
    // value acts as unique identifier for search history items
    value: string;
  };
}

export function useSearchHistory(initialValue?: string[]) {
  const [localSearchHistory, setLocalSearchHistory, clearSearchHistory] = useLocalStorage(
    'sterling_search_history',
    initialValue
  );

  // Filters out search history items that are older than 6 months and
  // sets the search history with the result
  const cleanOldSearchHistory = (newSearchValue?: string) => {
    const currentDate = new Date();
    const sixMonthsAgo = new Date(currentDate.setMonth(currentDate.getMonth() - 6));

    // Items that don't match the new search key and are older than 6 months
    const filteredSearchHistory = localSearchHistory.filter(
      (item: SearchHistoryItem) =>
        item.result.value !== newSearchValue || new Date(item.searchDate) > sixMonthsAgo
    );
    setLocalSearchHistory(filteredSearchHistory);
  };

  // Cleans search history and adds a search history item to the local storage
  const addSearchHistory = (
    searchKey: string,
    searchHistoryItem: Pick<SearchHistoryItem, 'result'>
  ) => {
    cleanOldSearchHistory(searchHistoryItem.result.value);
    const searchDate = new Date().toISOString();
    const existingSearchHistoryItem = localSearchHistory.find(
      (item: SearchHistoryItem) => item.result.value === searchHistoryItem.result.value
    );

    // If the search history item already exists, increment the rank
    if (existingSearchHistoryItem) {
      setLocalSearchHistory([
        {
          ...existingSearchHistoryItem,
          rank: existingSearchHistoryItem.rank + 1,
          keys: Array.from(new Set([searchKey, ...(existingSearchHistoryItem?.keys ?? [])])),
          searchDate,
        },
        ...localSearchHistory.filter(
          (item: SearchHistoryItem) => item.result.value !== searchHistoryItem.result.value
        ),
      ]);
      return;
    }

    // add current date to searchHistoryItem and updates search history
    setLocalSearchHistory([
      {
        ...{
          ...searchHistoryItem,
          rank: 1,
          keys: [searchKey],
          searchDate,
        },
      searchDate: new Date().toISOString() },
      ...localSearchHistory,
    ]);
  };

  return {
    searchHistory: localSearchHistory as SearchHistoryItem[],
    addSearchHistory,
    clearSearchHistory,
  };
}

const initialContentSearchValue = { contentSearch: [] };
const initialAutocompleteValue = { autocompleteSearch: [] };

export function useSearch(searchFor: string) {
  const lang = useLanguage();
  const searchHistoryHook = useSearchHistory([]);
  const [isPending, startTransition] = useTransition();
  const [currentSearch, setCurrentSearch] = useState('');
  const [searchResults, setSearchResults] = useState<ContentSearchQuery>(
    initialContentSearchValue
  );
  const [autocompleteResults, setAutocompleteResults] = useState<AutocompleteSearchQuery>(
    initialAutocompleteValue
  );
  const [startWait, setStartWait] = useState(0);

  const [searchHistory, setSearchHistory] = useState<
    { key: string; results: number; timing: number }[]
  >([]);

  const isSearchValid = currentSearch.length >= 2;
  const searchQuery = useQuery(CONTENT_SEARCH_QUERY, {
    variables: { language: lang, text: currentSearch },
    skip: !isSearchValid,
    returnPartialData: true,
    onCompleted: (data) => {
      setSearchResults(data);
    },
  });

  useQuery(AUTOCOMPLETE_SEARCH_QUERY, {
    variables: { language: lang, text: currentSearch },
    skip: !isSearchValid,
    returnPartialData: true,
    onCompleted: (data) => {
      setAutocompleteResults(data);
    },
  });

  const { data } = searchQuery as unknown as ContentSearchQueryResult;

  const handleSearchResultClick = (
    searchKey: string,
    result: { label: string; value: string }
  ) => {
    searchHistoryHook.addSearchHistory(searchKey, { result });
  };

  // eslint-disable-next-line
  const debouncedSave = useCallback(
    debounce((nextValue: string) => {
      setStartWait(performance.now()); // Set start time when search is initiated
      setCurrentSearch(nextValue);
    }, 500),
    []
  );

  const onClearClick = () => {
    setCurrentSearch('');
    setSearchResults(initialContentSearchValue);
    setAutocompleteResults(initialAutocompleteValue);
  };

  useEffect(() => {
    if (searchQuery.data) {
      const now = performance.now();
      setSearchHistory([
        {
          key: currentSearch,
          results: data?.contentSearch?.length ?? 0,
          timing: Math.floor(now - startWait),
        },
        ...searchHistory.slice(0, 9),
      ]);
    }
  }, [searchQuery.data, isSearchValid]);

  useEffect(() => {
    setStartWait(performance.now());
    if (searchFor !== currentSearch) {
      setStartWait(performance.now());
      startTransition(() => {
        debouncedSave(searchFor);
      });
    }
  }, [searchFor]);

  return {
    isSearchValid,
    isPending,
    autocompleteOptions: autocompleteResults,
    searchHistory,
    handleSearchResultClick,
    searchData: searchResults,
    onClearClick,
  };
}
