import { ThemeOptions } from '@mui/material/styles';
import 'assets/font-loaders/mmsc-fonts.css';
import { COMMON_COLORS, STERLING_COLOURS, withFallbacks } from './theme-sterling';

// eslint-disable-next-line no-console
console.info('Loading MMSC theme');

// This should not be exported to make sure the custom colours stay in scope of the theme
const COLOURS = {
  ...STERLING_COLOURS,
  mitsubishiRed: '#ed0000',
  concreteGrey: '#e3e5e6',
  coolGrey: '#bcc0ca',
  lightGrey: '#3F51B514',
  anthraciteGray: '#686d71',
} as const;

const mmscThemeOptions: ThemeOptions = {
  palette: {
    common: {
      ...COMMON_COLORS,
    },
    primary: {
      main: COLOURS.black,
      contrastText: COLOURS.white,
    },
    secondary: {
      main: COLOURS.mitsubishiRed,
      contrastText: COLOURS.white,
    },
    error: {
      main: COLOURS.mitsubishiRed,
      contrastText: COLOURS.white,
    },
    warning: {
      main: COLOURS.tba,
      contrastText: COLOURS.tba,
    },
    info: {
      main: COLOURS.anthraciteGray,
      contrastText: COLOURS.white,
      light: COLOURS.lightGrey,
    },
    success: {
      main: COLOURS.green,
      contrastText: COLOURS.tba,
    },
    background: {
      default: COLOURS.white,
      paper: COLOURS.concreteGrey,
      darkPaper: COLOURS.concreteGrey,
      dark: COLOURS.black, // Custom
      sterling: STERLING_COLOURS.seafoam,
    },
  },
  custom: {
    navLogo: {
      paddingTop: 148,
    },
    buttonFontPositionFix: {
      height: '1px !important',
      paddingTop: '20px !important',
      paddingBottom: '24px !important',
      smallSize: {
        paddingTop: '12px !important',
        paddingBottom: '14px !important',
        height: '34px !important',
        svg: { marginTop: '3px' },
      },
    },
    inputFontPositionFix: {
      height: '1.5em !important',
      paddingTop: '0.4em !important',
      paddingBottom: '0.5em !important',
    },
    inputLabel: {
      top: '-15%',
      left: '0%',
      '&[data-shrink="true"]': { transform: 'translate(20px,-4px) scale(0.75)' },
    },
  },

  typography: {
    fontFamily: withFallbacks('Mitsubishi Regular'),
    h1: {
      // fontFamily: withFallbacks('Mitsubishi Regular'),
      fontSize: '40px !important',
      fontFamily: withFallbacks('Mitsubishi Bold'),
    },
    h2: {
      fontFamily: withFallbacks('Mitsubishi Bold'),
    },
    h3: {
      fontFamily: withFallbacks('Mitsubishi Bold'),
    },
    h4: {
      fontFamily: withFallbacks('Mitsubishi Bold'),
    },
    h5: {
      fontFamily: withFallbacks('Mitsubishi Bold'),
    },
    h6: {
      fontFamily: withFallbacks('Mitsubishi Regular'),
    },
    subtitle1: {
      fontFamily: withFallbacks('Mitsubishi Regular'),
    },
    subtitle2: {
      fontFamily: withFallbacks('Mitsubishi Regular'),
    },
    body1: {
      fontFamily: withFallbacks('Mitsubishi Regular'),
    },
    body2: {
      fontFamily: withFallbacks('Mitsubishi Regular'),
    },
    caption: {
      fontFamily: withFallbacks('Mitsubishi Regular'),
    },
    button: {
      fontFamily: withFallbacks('Mitsubishi Regular'),
    },
  },
};

export default mmscThemeOptions;
