import React from 'react';
import {
  Select,
  MenuItem,
  Grid,
  Typography,
  Card,
  Link,
  Stack,
  // Typography
} from '@mui/material';
import { ContentAncestor, SearchResult } from 'generated';
import { useSearch } from './model.Search';

interface SearchItem {
  label: string;
  value: string;
  ancestors?: Partial<ContentAncestor>;
}

interface SearchBarProps {
  options?: SearchItem[];
}

export function NewSearchBar(props: SearchBarProps) {
  return (
    <Select inputMode="text" sx={{ width: '200px', borderRadius: '4px' }} variant="outlined">
      {props.options &&
        props.options.map((op: SearchItem) => (
          <MenuItem value={op.value}>{op.label}</MenuItem>
        ))}
    </Select>
  );
}

function NoResults() {
  return (
    <Grid item xs={12}>
      <Card
        elevation={3}
        sx={{
          p: 4,
          mb: 4,
          display: 'flex',
          justifyContent: 'space-between',
          borderRadius: '4px',
          backgroundColor: '#BCC0CA',
        }}
      >
        <Typography sx={{ textAlign: 'left' }}>No results found</Typography>
      </Card>
    </Grid>
  );
}

export function SearchResultsContainer(props: ReturnType<typeof useSearch>) {
  const {
    isSearchValid,
    searchData,
    isPending,
    autocompleteOptions,
    searchHistory,
    handleSearchResultClick,
  } = props;

  if (!isSearchValid) {
    return <></>;
  }

  return (
    <>
      <Grid
        container
        item
        lg={10}
        justifyContent="flex-start"
        sx={{ opacity: isPending ? '0.5' : '1' }}
      >
        {autocompleteOptions && (
          <Grid container item xs={12} justifyContent="flex-start" mb={10}>
            <Grid item xs={12}>
              <Typography sx={{ color: 'red', textAlign: 'left' }}>
                Auto-Complete Options:{' '}
              </Typography>
            </Grid>
            {autocompleteOptions?.autocompleteSearch?.map((option, idx) => (
              <Grid item mr={8}>
                <Typography sx={{ color: 'red', textAlign: 'left' }}>
                  {idx + 1}. {option.title}
                </Typography>
              </Grid>
            ))}
          </Grid>
        )}
        <Grid item xs={12} mb={10}>
          <Typography textAlign="left">Search History:</Typography>
          <Stack spacing={4} direction="row" overflow="auto" sx={{ width: '100%' }}>
            {[...searchHistory].map((item) => (
              <Card sx={{ minWidth: '250px !important', mr: 2, p: 2 }}>
                <Typography textAlign="left">Search: {item.key}</Typography>
                <Typography textAlign="left">Results: {item.results}</Typography>
                <Typography textAlign="left">Timing: {item.timing}ms</Typography>
              </Card>
            ))}
          </Stack>
        </Grid>

        {!searchData ? (
          <NoResults />
        ) : (
          searchData?.contentSearch &&
          searchData?.contentSearch?.map((item: Partial<SearchResult>, idx: number) => {
            return (
              <Grid item xs={3} key={item?._id}>
                <Link
                  onMouseDown={() =>
                    handleSearchResultClick(item.title ?? 'no title', {
                      label: item.title ?? 'no title',
                      value: item.path ?? 'no path',
                    })
                  }
                  href={item?.path}
                  target="_blank"
                >
                  <Card
                    elevation={3}
                    sx={{
                      p: 4,
                      mb: 4,
                      mr: 4,
                      display: 'flex',
                      flexGrow: 1,
                      justifyContent: 'space-between',
                      borderRadius: '4px',
                      backgroundColor: '#BCC0CA',
                    }}
                  >
                    <Typography sx={{ textAlign: 'left', textDecoration: 'none !important' }}>
                      {idx + 1}. {item?.title}
                    </Typography>
                    {item?.search_score && (
                      <Typography variant="caption" sx={{ textDecoration: 'none !important' }}>
                        Confidence: {Math.floor(((item?.search_score ?? 0) / 10) * 1000) / 10}%
                      </Typography>
                    )}
                  </Card>
                </Link>
              </Grid>
            );
          })
        )}
      </Grid>
      <Grid item xs={3}>
        {isPending && <Typography>Loading...</Typography>}
      </Grid>
    </>
  );
}
