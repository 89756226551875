import React, { ComponentProps, useEffect, useRef, useState } from 'react';
import {
  ClickAwayListener,
  Popper,
  Paper,
  MenuItem,
  InputBase,
  IconButton,
  Chip,
  Typography,
  Stack,
  Box,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from '@mui/icons-material/Search';
import { useSearch, useSearchHistory } from './model.Search';
import { LanguageLabel } from 'suites/sterling/app/types';
import { useLanguage } from 'components/util/Language';
// import HistoryIcon from '@mui/icons-material/History';
import ListAltIcon from '@mui/icons-material/ListAlt';
import RestoreIcon from '@mui/icons-material/Restore';

interface SearchMenuProps {
  searchValue?: string;
  label?: string;
  fieldWidth?: number;
  searchTermClear: () => void;
  onChange: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>;
}

interface MenuSection {
  optionType: LanguageLabel;
  items: { label: string; value: string; ancestors?: [{title: string; path: string;}]}[];
}

// eslint-disable-next-line @typescript-eslint/ban-types
function SearchItemIcon({
  category,
  ...rest
}: { category: string } & ComponentProps<typeof RestoreIcon>): JSX.Element {
  return category === 'Suggestions' ? (
    <RestoreIcon {...rest} />
  ) : category === 'Results' ? (
    <ListAltIcon {...rest} />
  ) : (
    <></>
  );
}

function SearchMenu({
  label,
  searchValue,
  autocompleteOptions,
  onChange,
  searchTermClear,
  searchData,
  onClearClick,
  fieldWidth = 350,
  handleSearchResultClick,
}: SearchMenuProps & ReturnType<typeof useSearch>): JSX.Element {
  const lang = useLanguage();
  const [open, setOpen] = useState(false);
  const searchFieldRef = useRef(null);
  const { searchHistory } = useSearchHistory();
  const initialSearchHistory = {
    optionType: { EN: 'Suggestions', FR: '[FR]Suggestions' },
    items: searchHistory
      .sort((a, b) => b.rank - a.rank)
      .map((item) => ({ label: item.result.label, value: item.result.value })),
  };
  const [menuOptions, setMenuOptions] = useState<MenuSection[]>([initialSearchHistory]);

  const handleClickAway = () => {
    setOpen(false);
  };

  const handleOptionUpdates = () => {
    const options = searchData?.contentSearch.map((d) => ({ label: d.title, value: d.path, ancestors: d.ancestors}));
    if (options !== undefined) {
      if (searchValue === '') {
        setMenuOptions([initialSearchHistory]);
      } else {
        const filteredOptions = menuOptions?.filter(
          (mO) => mO.optionType?.EN !== 'Results' && mO.optionType?.EN !== 'Pages'
        );
        const resultsSection = {
          optionType: { EN: 'Results', FR: '[FR]Results' },
          items: options,
        };
        const pagesSection = {
          optionType: { EN: 'Pages', FR: '[FR]Pages' },
          items: autocompleteOptions.autocompleteSearch.map((items) => ({
            label: items.title,
            value: items.path,
          })),
        };
        setMenuOptions(
          filteredOptions
            ? [pagesSection, ...filteredOptions, resultsSection]
            : [resultsSection]
        );
      }
    }
  };

  const handleSearchSelected = (
    option: { label: string; value: string },
    searchKey?: string
  ) => {
    handleSearchResultClick(searchKey ?? option.label, option);
    handleClickAway();
  };

  const handleMenuOpen = () => {
    if (menuOptions?.length) {
      setOpen(true);
    }
  };

  useEffect(() => {
    if (searchData?.contentSearch?.length && searchValue) {
      handleOptionUpdates();
    }
  }, [searchData?.contentSearch]);

  useEffect(() => {
    if (searchValue === '') {
      setMenuOptions([
        { ...initialSearchHistory, items: initialSearchHistory.items.slice(0, 10) },
      ]);
    } else {
      setMenuOptions([
        ...menuOptions.filter((mO) => mO.optionType.EN !== 'Suggestions'),
        {
          ...initialSearchHistory,
          items: searchHistory
            .filter((historyItem) =>
              historyItem.keys.some((val) => val.includes(searchValue ?? ''))
            )
            .sort((a, b) => b.rank - a.rank)
            .map((item) => ({ label: item.result.label, value: item.result.value })),
        },
      ]);
    }
  }, [searchValue]);

  console.log('locations', menuOptions)

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <Paper
        component="form"
        ref={searchFieldRef}
        sx={{
          display: 'flex',
          alignItems: 'center',
          width: fieldWidth,
          backgroundColor: 'white',
          borderRadius: '8px',
        }}
      >
        <InputBase
          sx={{ ml: 1, flex: 1, px: 1, py: 2 }}
          placeholder={label}
          onClick={handleMenuOpen}
          onChange={onChange}
          value={searchValue}
          inputProps={{
            'aria-label': 'content search bar',
            sx: {
              // placeholder font size
              '::placeholder': { fontSize: 14 },
            },
          }}
        />
        {!!searchValue && (
          <IconButton
            sx={{ display: 'flex' }}
            aria-label="clear button"
            disableRipple
            onClick={() => {
              onClearClick();
              searchTermClear();
              setMenuOptions([]);
            }}
          >
            <Chip
              sx={{
                alignItems: 'center',
                height: 0,
                pt: 2.5,
                mx: 0,
                pb: 3.75,
                mt: 1,
                px: 0,
              }}
              label={
                <Typography sx={{ fontSize: '14px !important', m: 0 }}>
                  Clear
                  <CloseIcon
                    sx={{ height: '16px', transform: 'translateY(20%)', m: 0, p: 0 }}
                  />
                </Typography>
              }
            />
          </IconButton>
        )}
        <IconButton
          type="button"
          sx={{ p: 2, width: 50, height: 50, borderRadius: '0 8px 8px 0' }}
          aria-label="search"
        >
          <SearchIcon />
        </IconButton>
        <Popper open={open} anchorEl={searchFieldRef.current} placement="bottom-start">
          <Paper
            sx={{
              backgroundColor: 'white',
              minWidth: fieldWidth,
              width: 'fit-content',
              mt: 4,
              pb: 2,
              '& .MuiMenuItem-root': {
                width: '100%',
              },
            }}
          >
            {menuOptions?.map((category) =>
              category.items.length ? (
                <Box key={category.optionType.EN} sx={{ width: '100%' }}>
                  <Typography variant='body2' sx={{ ml: 4, color: '#333 !important' }}>
                    {category.optionType[lang]}
                  </Typography>
                  {category?.items?.map((option) => (
                    <Stack
                      direction="row"
                      m={0}
                      alignItems="center"
                      key={`${category.optionType.EN}_${option.value}`}
                    >
                      <MenuItem onMouseDown={() => handleSearchSelected(option, searchValue)}>
                        <SearchItemIcon
                          category={category.optionType.EN}
                          sx={{ width: '0.75em', height: '0.75em', mr: 2 }}
                        />
                        {option.label}{' '}
                        {option?.ancestors?.at(-1)?.title && (
                          <Typography variant='button' sx={{ ml: 1, color: '#777' }}>
                            in {option?.ancestors?.at(-1)?.title}
                          </Typography>
                        )}
                      </MenuItem>
                    </Stack>
                  ))}
                </Box>
              ) : (
                <></>
              )
            )}
            {menuOptions.reduce((acc, category) => acc + category.items.length, 0) >= 10 && (
              <MenuItem>
                {{ EN: 'View all results', FR: '[FR]View all results' }[lang]}
              </MenuItem>
            )}
          </Paper>
        </Popper>
      </Paper>
    </ClickAwayListener>
  );
}

export default SearchMenu;
