/* eslint-disable @typescript-eslint/no-explicit-any */
import { Grid, Stack, Typography } from '@mui/material';
// import React, { Suspense } from 'react';
import React from 'react';
import { ContentSection } from 'suites/sterling/molecules/sections/content/ContentSection';
import { PageShell } from 'suites/sterling/molecules/shells/page/PageShell';
import { SearchResultsContainer } from './SearchBar';
import SearchMenu from './SearchMenu';
import { useSearch } from './model.Search';

export function SearchPlayground() {
  const [searchText, setSearchText] = React.useState('');
  const handleSeachTextChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(event.target.value);
  };
  const searchModel = useSearch(searchText);

  return (
    <PageShell>
      <ContentSection
        maxWidth="xl"
        title={
          <>
            ``
            <Stack direction="column" alignSelf="flex-start" gap={10}>
              <Typography variant="h1">Search Playground</Typography>
            </Stack>
          </>
        }
      >
        <Stack direction="row" container justifyContent="start" gap={10} component={Grid}>
          <Grid item lg={5} sx={{ textAlign: 'left' }}>
            <SearchMenu
              onChange={handleSeachTextChange}
              searchValue={searchText}
              searchTermClear={() => setSearchText('')}
              label="Search for standards, modules or criteria..."
              {...searchModel}
            />
          </Grid>
          <SearchResultsContainer {...searchModel} />
        </Stack>
      </ContentSection>
    </PageShell>
  );
}
